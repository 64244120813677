<template>
  <div class="custom-card card w-100 d-flex flex-column">
    <div
      class="
        custom-card-header
        d-flex
        justify-content-between
        align-items-center
        px-2
      "
    >
      <div class="d-flex align-items-center">
        <div style="color: #4361ee; font-size: 22px">
          <i class="far fa-image"></i>
        </div>
        <div class="mx-2">
          <h5 class="mb-0">Building Images</h5>
          <p class="mb-0" style="opacity: 0.7">Enter your Building Images</p>
        </div>
      </div>
      <div v-if="currentPage > 1" style="cursor: pointer" @click="isOpen = !isOpen">
        <i class="fas fa-angle-down"></i>
      </div>
      <div v-else style="cursor: pointer">
        <i class="fas fa-angle-down"></i>
      </div>
    </div>
    <div class=" d-flex w-100 position-relative"  v-if="currentPage > 1 || isOpen">
      <div class="custom-left">
        <div
          class="custom-box"
          @dragenter="dragging = true"
          @dragend="dragging = false"
          @dragleave="dragging = false"
          @dragover="useOnDragOver($event)"
          @drop="useOnDrop($event)"
        ></div>
        <div class="text-center custom-upload-image" @click="openFile">
          Upload here
        </div>
        <button v-if="loadingButton == 0" class="btn btn-primary" @click="sendPictures">Next</button>
        <button style="cursor:wait;" v-else class="btn btn-secondary">Submitting</button>
        <input
          id="buildings_file_input"
          type="file"
          name="article_picture"
          style="display: none"
          @change="buttonFileAdd"
        />
      </div>
      <div class="custom-right p-2 d-flex justify-content-between flex-wrap">
        <div
          class="custom-image-box"
          v-for="(item, index) in justFiles"
          :key="index"
        >
          <img :src="item" alt="" />
          <input
            type="text"
            class="custom-caption-input form-control mt-1"
            placeholder="Add Caption"
            v-model="files[index].caption"
            @change="changeCaption($event, index)"
          />
        </div>
        <!-- <div v-if="pageTwo.images.length > 0"> -->
          <div
          class="custom-image-box"
          v-for="(item, index) in pageTwo.images"
          :key="index"
        >
          <img :src="item.image" alt="" />
          <input
            type="text"
            class="custom-caption-input form-control mt-1"
            placeholder="Add Caption"
            v-model="item.caption"
            disabled
            @change="changeCaption($event, index)"
          />
        <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      building_id:1,
      files: [],
      justFiles: [],
      imageLink: [],
      file: null,
      dragging: false,
    };
  },
  methods: {
    openFile() {
      document.getElementById("buildings_file_input").click();
    },
    buttonFileAdd(e) {
      console.log(e);
      const fileToBeEntered = e.target.files[0];
      if (
        fileToBeEntered.type == "image/jpeg" ||
        fileToBeEntered.type == "image/jpg" ||
        fileToBeEntered.type == "image/png"
      ) {
        this.imageLink = fileToBeEntered;
        let fileUrl;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(fileToBeEntered);
        fileReader.onload = () => {
          fileUrl = fileReader.result;
          if (!this.justFiles.includes(fileUrl)) {
            this.file = fileUrl;
            this.files.push({
              image: this.imageLink,
              type: fileToBeEntered.type.split("/")[1],
              caption: `Caption ${this.files.length + 1}`,
            });
            this.justFiles.push(this.file);
            console.log("Files : ", this.files);
            console.log("Just Files: ", this.justFiles);
          } else {
            alert("Same picture uploaded");
          }
        };
        fileReader.onerror = () => {
          console.log("error");
        };
      } else {
        alert("Only Image files accepted");
      }
    },
    useOnDragOver(e) {
      this.dragging = true;
      e.preventDefault();
      console.log("File is over drag area!");
    },
    useOnDrop(e) {
      this.dragging = false;
      console.log("Enter useondrop", e);
      e.preventDefault();
      console.log("File dropped");
      const fileToBeEntered = e.dataTransfer.files[0];
      if (
        fileToBeEntered.type == "image/jpeg" ||
        fileToBeEntered.type == "image/jpg" ||
        fileToBeEntered.type == "image/png"
      ) {
        this.imageLink = fileToBeEntered;
        console.log("Picture in file format  is:", this.imageLink);
        let fileUrl;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(fileToBeEntered);
        fileReader.onload = () => {
          console.log("In Base 64");
          fileUrl = fileReader.result;
          if (!this.justFiles.includes(fileUrl)) {
            this.file = fileUrl;
            console.log("Pic inserted:", this.file);
            this.files.push({
              image: this.imageLink,
              type: fileToBeEntered.type.split("/")[1],
              caption: "",
            });
            this.justFiles.push(this.file);
            console.log("Files : ", this.files);
            console.log("Just Files: ", this.justFiles);
          } else {
            alert("Same picture uploaded");
          }
        };
        fileReader.onerror = () => {
          console.log("error");
        };
      } else {
        alert("Only Image files accepted");
      }
    },
    changeCaption(e, index) {
      this.files[index].caption = e.target.value;
      console.log("LOLKKKK", this.files[index].caption);
    },

    sendPictures() {
      this.setBuildingImages(this.files);
    },
  },

  props: ["setBuildingImages", "loadingButton", "pageTwo", "currentPage"],
};
</script>
<style lang="scss" scoped>
.custom-card {
  min-height: 6rem;
  padding: 1rem;
}
.custom-card-header {
  height: 6rem;
}
.custom-left {
  width: 40%;
  max-height: 18rem;
  background-color: pink;
}
.custom-right {
  width: 60%;
  height: 20rem;
  overflow-y: scroll;
}
.custom-box {
  width: 100%;
  height: 15rem;
  background-color: #f5f5f5;
}
.custom-upload-image {
  color: #4361ee;
  font-weight: 500;
  cursor: pointer;
  margin: 1rem 0;
}
.custom-image-box {
  height: 15rem;
  position: relative;
  margin-bottom: 1rem;
  width: 48%;
  img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.custom-caption-input {
  text-align: center;
  font-weight: 500;
  color: #4361ee;
}
</style>

<template>
  <div class="custom-card-item card w-100 d-flex flex-column">
    <div
      class="custom-card-header d-flex justify-content-between align-items-center px-2"
    >
      <div class="d-flex align-items-center">
        <div style="color:#4361EE;font-size:22px;">
          <img src="@/assets/Real estate/Vector.svg" alt="">
        </div>
        <div class="mx-2">
          <h5 class="mb-0">Building Details</h5>
          <p class="mb-0" style="opacity:0.7;">Enter your Building Information</p>
        </div>
      </div>
      <div v-if="currentPage > 0" style="cursor:pointer" @click="isOpen = !isOpen"><i class="fas fa-angle-down"></i></div>
      <div v-else style="cursor:pointer" ><i class="fas fa-angle-down"></i></div>

    </div>
    <div class="custom-card-details" v-if="currentPage > 0 || isOpen">
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4 ">
            <label class="mb-0"><h6>Name</h6></label>
            <input type="text" class="form-control" placeholder="Enter Name" v-model="pageOne.name" />
          </div>
          <!-- <div class="col-4 ">
            <label class="mb-0"><h6>Email</h6></label>
            <input
              type="email"
              class="form-control"
              placeholder="Enter Email" v-model="email"/>
          </div> -->
          <div class="col-4 ">
            <label class="mb-0"><h6>Phone Number</h6></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Phone no."
              v-model="pageOne.phone"
            />
          </div>
          <div class="col-4 ">
            <label class="mb-0"><h6>Number of Units</h6></label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter No. of Units."
              v-model="pageOne.number_of_units"
              :disabled="isEdit ? true : false"
            />
          </div>
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-12">
            <label class="mb-0"><h6>Address</h6></label>
            <textarea
              name="address"
              id=""
              rows="4"
              placeholder="Enter Address"
              class="w-100 form-control"
              v-model="pageOne.address"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4 ">
            <label class="mb-0"><h6>State</h6></label>
            <multiselect
              v-model="pageOne.state"
              :options="options"
              class="helo"
            ></multiselect>
          </div>
          <div class="col-4 ">
            <label class="mb-0"><h6>City</h6></label>
            <input type="text" class="form-control" placeholder="Enter City" v-model="pageOne.city"/>
          </div>
          <div class="col-4 ">
            <label class="mb-0"><h6>Zip / Postal code</h6></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Postal Code"
              v-model="pageOne.zip"
            />
          </div>
        </div>
      </div>
      <button v-if="loadingButton == 0" class="btn btn-primary" @click="useSetBuildingInfo">Next</button>
      <button style="cursor:wait;" v-else class="btn btn-secondary" >Submitting</button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      isOpen: true,
      building_name : '',
      phone_no : '',
      address : '',
      state:'Andhra Pradesh',
      city : '',
      zipcode : '',
      email :'',
      options: [ "Andhra Pradesh",
                "Arunachal Pradesh",
                "Assam",
                "Bihar",
                "Chhattisgarh",
                "Goa",
                "Gujarat",
                "Haryana",
                "Himachal Pradesh",
                "Jammu and Kashmir",
                "Jharkhand",
                "Karnataka",
                "Kerala",
                "Madhya Pradesh",
                "Maharashtra",
                "Manipur",
                "Meghalaya",
                "Mizoram",
                "Nagaland",
                "Odisha",
                "Punjab",
                "Rajasthan",
                "Sikkim",
                "Tamil Nadu",
                "Telangana",
                "Tripura",
                "Uttarakhand",
                "Uttar Pradesh",
                "West Bengal",
                "Andaman and Nicobar Islands",
                "Chandigarh",
                "Dadra and Nagar Haveli",
                "Daman and Diu",
                "Delhi",
                "Lakshadweep",
                "Puducherry"]
    };


  },

  methods : {
    useSetBuildingInfo(){
      this.setBuildingInfo(this.pageOne.name, this.pageOne.phone, this.pageOne.address, "India", this.pageOne.state, this.pageOne.city, this.pageOne.zip, this.pageOne.number_of_units)
    }
    
  },


  components: {
    Multiselect,
  },


  props : ["setBuildingInfo","loadingButton","unitsNumber","pageOne", "isEdit", "currentPage"]
};
</script>
<style lang="scss" scoped>
.custom-card-item {
  padding: 1rem;
  // height: 10rem;
}
.custom-card-header {
  height: 6rem;
}
</style>

<template>
  <div class="w-100">
    <Preloader v-if="false" />
    <div class="custom-blank-navbar d-flex justify-content-between align-items-center w-100 bg-white px-4 py-2">
      <img src="@/assets/Pg-logo2.png" @click="home" style="cursor:pointer; height:3rem; width:auto;"/>
      <button class="btn btn-close" @click="back">x Close</button>
    </div>
    <div class="w-75 position-relative mx-auto">
      <h1>Add Building Details</h1>
    </div>
    <div class="w-75 position-relative mx-auto">
      <BuildingInfo :currentPage="currentPage" :isEdit="isEdit" :setBuildingInfo="setBuildingInfo" :tabOpen="tabOpen" :loadingButton="loadingButton" :pageOne="pageOne" :unitsNumber="number_of_units" />
    </div>
    <div class="w-75 position-relative mx-auto">
      <BuildingImages :currentPage="currentPage" :setBuildingImages="setBuildingImages" :tabOpen="tabOpen" :loadingButton="loadingButton" :pageTwo="pageTwo" />
    </div>
    <div class="w-75 position-relative mx-auto">
      <BuildingAmenities :currentPage="currentPage" :setBuildingAmenities="setBuildingAmenities" :tabOpen="tabOpen" :loadingButton="loadingButton" :pageThree="pageThree" />
    </div>
  </div>
</template>
<script>
import BuildingInfo from "@/new-components/listing-items/BuildingInfo.vue";
import BuildingImages from "@/new-components/listing-items/BuildingImages.vue";
import BuildingAmenities from "@/new-components/listing-items/BuildingAmenities.vue";
import Preloader from "@/new-components/Preloader/Preloader.vue";
import { buildingPage1, buildingPage2, buildingPage3, getCurrentBuilding } from "@/utils/apis.js";
export default {
  data() {
    return {
      id: "",
      currentPage: 1,

      //Building page 1
      name: "",
      phone: "",
      address: "",
      country: "",
      state:"",
      city: "",
      zip: "",
      tabOpen:0,
      number_of_units: "",

      //Building page 2
      images: [],
      loadingButton:0,

      pageOne:{
        name: "",
        phone: "",
        address: "",
        country: "",
        state:"",
        city: "",
        zip: "",
        number_of_units: "",
      },
      pageTwo:{
        images: [],
      },
      pageThree:{
        amenities: [],
      },
      isEdit: false,
      //Building Page 3
    };
  },

  methods: {
    async setBuildingInfo(name, phone, address, country, state, city, zip, units) {
      this.name = name;
      this.phone = phone;
      this.address = address;
      this.country = country;
      this.state = state;
      this.city = city;
      this.zip = zip;
      this.loadingButton = 1;

      if (
        !this.name || !this.phone || !this.address || !this.country || !this.city || !this.zip ) {

          this.$toasted.error("Please fill all details", {
            position: "top-center",
            duration: 3000,
          });

        } else {
          let apiBody = {
            building_name: this.name,
            phone_no: this.phone,
            address: this.address,
            country: this.country,
            state: this.state,
            city: this.city,
            zipcode: parseInt(this.zip),
            no_of_units: parseInt(units),
          };

          if(this.id){
            apiBody.building_id = this.id;
          }

          const data = await buildingPage1(apiBody);
          if (data.success) {
            this.currentPage = 2;
            this.id = data.building_id;
            this.$toasted.success("Step 1 Completed", {
              position: "top-center",
              duration: 3000,
            });
            this.tabOpen += 1;
          } else {
            this.$toasted.error("Please try again later!!", {
              position: "top-center",
              duration: 3000,
            });
          }
        }
      this.loadingButton = 0;
    },

    async setBuildingImages(files) {
      this.images = files;
      console.log("Images here:", this.images);
      this.loadingButton = 2;
      const data = await buildingPage2(this.id, this.images);
      console.log("Data we got", data);

      if (data.success) {
        this.currentPage = 3;
        this.$toasted.success("Step 2 Completed", {
          position: "top-center",
          duration: 3000,
        });
        this.tabOpen += 1;
      } else {
        this.$toasted.error("Please try again later!!", {
          position: "top-center",
          duration: 3000,
        });
      }
      this.loadingButton = 0;
    },

    async setBuildingAmenities(data) {
      const reqBody = {
        building_id: parseInt(this.id) ? parseInt(this.id) : 36,
        amn: data.amenities,
        // no_of_units: parseInt(data.number_of_units),
      }
      this.loadingButton = 3;

      const result = await buildingPage3(reqBody);
      console.log(result);
      this.tabOpen += 1;
      this.loadingButton = 0;
      if(result.success){
        this.$toasted.success("Step 3 Completed", {
          position: "top-center",
          duration: 3000,
        });
        this.$router.push({path:'/pages/listings/'});
      }

    },

    async useGetBuildingsById() {
      if(this.$router.currentRoute.path.includes('edit-listing')){
        const data = await getCurrentBuilding(this.$router.currentRoute.params.id);
        console.log(data.data.building);
        this.id = data.data.building.id;
        this.pageOne.address = data.data.building.address;
        this.pageOne.city = data.data.building.city;
        this.pageOne.country = data.data.building.country;
        this.pageOne.state = data.data.building.state;
        this.pageOne.zip = data.data.building.zipcode;
        this.pageOne.name = data.data.building.building_name;
        this.pageOne.phone = data.data.building.phone_no;
        this.pageOne.number_of_units = data.data.building.no_of_units;
        if(data.data.building.images){
          let array = [];
          data.data.building.images.forEach(element => {
            array.push({image:element.image_url, caption:element.caption});
          });
          this.pageTwo.images = [...array];
        }
        if(data.data.building.amenities){
          let array = [];
          data.data.building.amenities.forEach(element => {
            array.push(element.amenities);
          });
          this.pageThree.amenities = [...array];
        }
      }
    },
    
    home(){
      this.$router.push("/pages/listings")
    },
    back(){
      this.$router.push("/pages/listings")
    },
  },

  components: {
    BuildingInfo,
    BuildingImages,
    BuildingAmenities,
    Preloader,
  },

  mounted() {
    this.useGetBuildingsById();
    if(this.$router.currentRoute.path.includes('edit')){
      this.isEdit = true;
    }
    window.onbeforeunload = function() {
      return "Data will be lost if you leave the page, are you sure?";
    };
  },
};
</script>
<style lang="scss" scoped>
.custom-blank-navbar {
  margin-bottom: 3rem;
}
.btn-close{
  width: 73px;
  height: 26px;

  background: #FFFFFF;
  border: 0.5px solid #F72585;
  color:#f72585;
  border-radius: 4px;
}
</style>
<template>
  <div class="custom-card w-100 card d-flex flex-column">
    <div
      class="custom-card-header d-flex justify-content-between align-items-center px-2"
    >
      <div class="d-flex align-items-center">
        <div style="color:#4361EE;font-size:22px;">
          <i class="fas fa-wifi"></i>
        </div>
        <div class="mx-2">
          <h5 class="mb-0">Building Aminities</h5>
          <p class="mb-0" style="opacity:0.7;">Enter your Building Images</p>
        </div>
        </div>
      <div v-if="currentPage > 2" style="cursor:pointer" @click="isOpen = !isOpen"><i class="fas fa-angle-down"></i></div>
      <div v-else style="cursor:pointer"><i class="fas fa-angle-down"></i></div>

    </div>
    <div class="custom-card-details" v-if="currentPage > 2 || isOpen">
      <div class="container mb-4">
        <div class="row w-full">
          <!-- <div class="col-4 ">
            <label class="mb-0"><h6>No of Units Available</h6></label>
            <input type="number" min="1" max="1000" class="form-control" placeholder="No of units" v-model="number_of_units" />
          </div> -->
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-12">
            <label class="mb-0"><h6>Amenities you offer</h6></label>
            <div class="w-100 d-flex justify-content-start align-items-center flex-wrap" v-if="real_amenities.length > 0">
              <div class="form-check m-2" v-for="(item,index) in real_amenities" :key="index"><input type="checkbox" class="form-check-input" :checked="pageThree.amenities.includes(item.id)" @click="setSelectedAmenities(item.id)"><label class="form-check-label" for="">{{item.name}}</label></div>
            </div>
          </div>
        </div>
      </div>
    <button v-if="loadingButton == 0" class="btn btn-primary" @click="useSetBuildingAmenities">Submit</button>
    <button style="cursor:wait;" v-else class="btn btn-secondary" >Submitting</button>
    </div>
  </div>
</template>
<script>
import { getAmenities} from '@/utils/apis.js';
export default {
  data() {
    return {
      isOpen: false,
      number_of_units : '',
      options: [
        "India",
        "United States of America",
        "Great Britain",
        "Scotland",
        "Ireland",
        "Kenya",
        "Zimbabwe",
        "Sri Lanka",
        "Bangladesh",
        "Pakistan"
      ],
      amenities:[
        {
          name:"Air Conditioning",
          value:false,
        },
        {
          name:"Wifi",
          value:false,
        },
        {
          name:"Parking",
          value:false,
        },
        {
          name:"Laundry",
          value:false,
        },
        {
          name:"Pets Allowed",
          value:false,
        },
        {
          name:"Swimming Pool",
          value:false,
        },
        {
          name:"Gym",
          value:false,
        },
        {
          name:"Elevator",
          value:false,
        },
        {
          name:"Lift",
          value:false,
        },
        {
          name:"Security",
          value:false,
        },
        {
          name:"Fire Safety",
          value:false,
        },
        {
          name:"24 Hour Security",
          value:false,
        },
        {
          name:"24 Hour Power Backup",
          value:false,
        },
        {
          name:"24 Hour Water Supply",
          value:false,
        },
        
      ],
      amenities_selected:[],
      real_amenities:[],
      
    };


  },

  methods : {
    async getAmenities(){
      let result = await getAmenities();
      this.real_amenities = result.amenities;
    },

    setSelectedAmenities(id){
      if(this.amenities_selected.includes(id)){
        this.amenities_selected.splice(this.amenities_selected.indexOf(id),1)
      }else{
        this.amenities_selected.push(id)
      }
    },

    useSetBuildingAmenities(){
      this.setBuildingAmenities({
        number_of_units: this.number_of_units,
        amenities: this.amenities_selected})
    }

    
  },
  components: {
    
  },
  mounted() {
    this.getAmenities();
  },

  props : ["setBuildingAmenities", "loadingButton", "pageThree", "currentPage"]
};
</script>
<style lang="scss" scoped>
.custom-card {
  min-height: 6rem;
  padding: 1rem;
}
.custom-card-header {
  height: 6rem;
}
</style>
